import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import styles from './ZaiPayment.module.scss';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { postZaiCardAuthToken, postZaiMakePayment } from 'utils/http/BillingService/zai';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

const ZaiPayment = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const { paymentRequestId } = useParams();
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const [cardToken, setCardToken] = useState<string>();
  const [paymentStatus, setPaymentStatus] = useState('PENDING');
  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    if (!token) return;
    (async () => {
      try {
        const response = await postZaiCardAuthToken({ token });
        const data = await response.json();
        if (data) {
          setCardToken(data.token);
        }
      } catch (error) {
        console.error(error);
        notification.error({ message: 'Something went wrong while trying to show credit card form.' });
      }
    })();
  }, [token, clientRecordId]);

  useEffect(() => {
    // @ts-ignore
    if (cardToken && paymentRequestId && window.Dropin) {
      setFormLoading(false);
      try {
        // @ts-ignore
        Dropin.destroy();
      } catch (e) {
        console.error(e);
      }
      setPaymentStatus('PENDING');
      // @ts-ignore
      Dropin.create(
        {
          cardTokenAuth: cardToken,
          environment: 'prelive',
          targetElementId: '#ZaiPayment',
          // @ts-ignore
          cardAccountCreationCallback: async () => {
            try {
              const response = await postZaiMakePayment({ token, paymentRequestId });
              if (response.statusCode === 200) {
                setPaymentStatus('COMPLETED');
              }
            } catch (error) {
              setPaymentStatus('FAILED');
              console.error(error);
              notification.error({ message: 'Something went wrong while trying to make payment.' });
            } finally {
              // @ts-ignore
              Dropin.destroy();
            }
          }
        },
        // @ts-ignore
        function (error) {
          if (error) {
            console.error(error);
            notification.error({ message: 'Something went wrong while trying to submit credit card form.' });
          }
        }
      );
    }
  }, [cardToken, paymentRequestId, token]);
  return (
    <div className={styles.container}>
      <Helmet>
        <script src="https://hosted.assemblypay.com/assembly.js" />
        <meta httpEquiv="refresh" content="1800" />
      </Helmet>
      <div>zai payment</div>
      {formLoading && <LoadingCircle />}
      <div id="ZaiPayment" />
      {paymentStatus === 'COMPLETED' && (
        <div>
          <h1>Payment completed!</h1>
        </div>
      )}
      {paymentStatus === 'FAILED' && (
        <div>
          <h1>Payment failed! Please try again</h1>
        </div>
      )}
      {paymentStatus === 'PENDING' && !paymentRequestId && (
        <div>
          <h1>Payment request not found</h1>
        </div>
      )}
    </div>
  );
};

export default ZaiPayment;
