import { BentResponse } from 'bent';
import { httpClient } from '../../httpClient';

const url = process.env.REACT_APP_BILLING_SERVICE_URL;

export interface ZaiItem {
  buyer_id: string;
  seller_id: string;
  name: string;
  amount: number;
}

export const postZaiCardAuthToken = ({ token }: { token: string }) =>
  httpClient('POST', url!, 200, { Authorization: `Bearer ${token}` })(
    '/clients/me/payment-methods/zai:generateCardUpdateAuthToken'
  ) as Promise<BentResponse>;

export const postZaiMakePayment = ({ token, paymentRequestId }: { token: string; paymentRequestId: string }) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client/me/zai/payments-requests/${paymentRequestId}:makePayment`
  ) as Promise<BentResponse>;
